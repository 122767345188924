import { Controller } from 'stimulus';
import debounce from 'lodash.debounce';

// This controller allows for various debounced actions to be taken
// on DOM elements.
export default class extends Controller {
  static targets = [];

  // Do not manually call this from an action
  _submitForm() {
    this.element.form.requestSubmit();
  }

  // Submit the form after some number of inactive milliseconds or some maximum number of
  // milliseconds, whichever comes first. These values are defined in the initialize() function.
  //
  // @data [Integer] debounceMs the number of milliseconds to wait before executing the function.
  //       Defaults to 500.
  // @data [Integer] debounceMaxWaitMs after this many milliseconds, the form will always submit.
  //       Defaults to 2000.
  //
  // @see https://github.com/lodash/lodash/blob/master/debounce.js for docs on debounce()
  debouncedSubmit = debounce(
    this._submitForm,
    this.data.get('debounceMs') || 500,
    { maxWait: this.data.get('debounceMaxWaitMs') || 2000 },
  );

  // Set up some basic listeners to handle state for the entire controller.
  initialize() {
    // Whenever the form element submits by some method other than the debouncedSubmit,
    // we need to make sure we don't double submit.
    this.element.form.addEventListener('ajax:send', () => {
      this.debouncedSubmit.cancel();
    });
  }
}
